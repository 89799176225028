const localAppMetadata = {
    "_version": 62,
    "locale": "en-us",
    "uid": "blt169555ec6824b6ac",
    "ACL": {},
    "_in_progress": false,
    "created_at": "2023-03-06T15:29:09.646Z",
    "created_by": "blt84c7dbee95981303",
    "field_specific_prompt": {
        "json_rte": ""
    },
    "generate_from_other_fields": [
        {
            "title": "Outline",
            "_metadata": {
                "uid": "cs8287640d5de2511a"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt7641c90269ac3aa4",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:51:36.328Z",
                "updated_at": "2023-06-30T11:51:36.328Z",
                "content_type": "image/svg+xml",
                "file_size": "1330",
                "filename": "ListBullets.svg",
                "title": "ListBullets.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.257Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt7641c90269ac3aa4/649ec1c81ea82969c4f58663/ListBullets.svg"
            },
            "prompt": "Generate an outline from this text: {{field_data}}",
            "testid": "generate-outline"
        },
        {
            "title": "Headline",
            "_metadata": {
                "uid": "cse0c23377e2dd4f39"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt40d5b43a35413240",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:52:47.700Z",
                "updated_at": "2023-06-30T11:52:47.700Z",
                "content_type": "image/svg+xml",
                "file_size": "1157",
                "filename": "TextHOne.svg",
                "title": "TextHOne.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.282Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt40d5b43a35413240/649ec20fa60a7946664128cf/TextHOne.svg"
            },
            "prompt": "Write an SEO-enhanced headline without double quotes for this text: {{field_data}}",
            "testid": "generate-headline"
        },
        {
            "title": "Summary",
            "_metadata": {
                "uid": "cs1593b8f642f8c5fc"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt0f409b9c4c6aa2fa",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:52:23.826Z",
                "updated_at": "2023-06-30T11:52:23.826Z",
                "content_type": "image/svg+xml",
                "file_size": "1582",
                "filename": "Multiline_Textbox.svg",
                "title": "Multiline_Textbox.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.309Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt0f409b9c4c6aa2fa/649ec1f7e9365ae3cfc364bb/Multiline_Textbox.svg"
            },
            "prompt": "Generate a summary from this text: \n{{field_data}}",
            "testid": "generate-summary"
        },
        {
            "title": "SEO Tags",
            "_metadata": {
                "uid": "cs3cd0457d2bf34855"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt18f39e2e8b6f8ac0",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T10:39:04.327Z",
                "updated_at": "2023-06-30T10:39:04.327Z",
                "content_type": "image/svg+xml",
                "file_size": "1822",
                "filename": "Tag.svg",
                "title": "Tag.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.334Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt18f39e2e8b6f8ac0/649eb0c89c69d847ffc1d78b/Tag.svg"
            },
            "prompt": "SEO keywords are words or phrases related to web content take make it possible for search engines to index content in a way that people will find it. SEO keywords do not need to be specifically mentioned in the text, but should be relevant and should help people find this text in search engines.\n\nSEO tags should be separated by commas, without a period at the end and should be all lower case, like this: \n\ntechnology, composable industry, search engine performance, SEO\n\nAct as a professional personalization expert and generate several SEO tags from this text: {{field_data}}",
            "testid": "generate-seo-tags"
        },
        {
            "title": "User Persona Tags",
            "_metadata": {
                "uid": "cs7ff61ea0f1758a48"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt18f39e2e8b6f8ac0",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T10:39:04.327Z",
                "updated_at": "2023-06-30T10:39:04.327Z",
                "content_type": "image/svg+xml",
                "file_size": "1822",
                "filename": "Tag.svg",
                "title": "Tag.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.334Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt18f39e2e8b6f8ac0/649eb0c89c69d847ffc1d78b/Tag.svg"
            },
            "prompt": "A persona represents a segment of audience members who exhibit similar behaviors or have similar interests. Persona tags can be used to identify audience members who would be interested in this blog post for the purpose of personalization and audience targeting. \n\nKey persona tags should be separated by commas, without a period at the end and should be all lower case, like this: \n\nmarketing professionals, developers, business users, pet owners\n\nAct as a professional personalization expert and generate several key persona tags from this text: \"{{field_data}}\"",
            "testid": ""
        },
        {
            "title": "Blog Post",
            "_metadata": {
                "uid": "cs4165d3fa62c3b35a"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "bltc919da1a30277b1d",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T10:39:04.323Z",
                "updated_at": "2023-06-30T10:39:04.323Z",
                "content_type": "image/svg+xml",
                "file_size": "1869",
                "filename": "Create_file.svg",
                "title": "Create_file.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.394Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltc919da1a30277b1d/649eb0c813bff899212edcc9/Create_file.svg"
            },
            "prompt": "Generate an SEO-enhanced blog post from this text: \n{{field_data}}",
            "testid": "generate-blog-post"
        }
    ],
    "model_configuration": {
        "max_tokens": 2048,
        "model": "gpt-3.5-turbo",
        "temperature": 0.75,
        "top_p": null
    },
    "operation_on_current_field": [
        {
            "title": "Re-write this text",
            "_metadata": {
                "uid": "csa7baf16815bf7d27"
            },
            "tooltip": "Re-phrase the existing content",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blta345833349b2d3fe",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:52:23.829Z",
                "updated_at": "2023-06-30T11:52:23.829Z",
                "content_type": "image/svg+xml",
                "file_size": "1298",
                "filename": "Refresh.svg",
                "title": "Refresh.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.420Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blta345833349b2d3fe/649ec1f7e9365ac642c364bd/Refresh.svg"
            },
            "prompt": "Rephrase this text to enhance SEO but keep it the same length {{json_rte_prompt}}: {{field_data}}",
            "testid": "rewrite-this-text"
        },
        {
            "title": "Shorten this text",
            "_metadata": {
                "uid": "cs840e01029a67a1ed"
            },
            "tooltip": "Rephrase the content with a shorter length",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "bltebf71bf630d13748",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:50:57.919Z",
                "updated_at": "2023-06-30T11:50:57.919Z",
                "content_type": "image/svg+xml",
                "file_size": "2428",
                "filename": "ArrowsIn.svg",
                "title": "ArrowsIn.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.447Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltebf71bf630d13748/649ec1a148bdd214a7104f85/ArrowsIn.svg"
            },
            "prompt": "Shorten this text by 50% {{json_rte_prompt}}: \n{{field_data}}",
            "testid": "shorten-this-text"
        },
        {
            "title": "Translate to current locale language",
            "_metadata": {
                "uid": "cs5e5597a17e69004f"
            },
            "tooltip": "Translate this field into language of this current locale.",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt6312416370c683c2",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:51:36.326Z",
                "updated_at": "2023-06-30T11:51:36.326Z",
                "content_type": "image/svg+xml",
                "file_size": "2230",
                "filename": "Language.svg",
                "title": "Language.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.473Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt6312416370c683c2/649ec1c829ad9885b3826bcc/Language.svg"
            },
            "prompt": "Translate this text into {{current_locale}} {{json_rte_prompt}}: {{field_data}}",
            "testid": "translate-to-current-locale"
        }
    ],
    "section_1": "Replace field content",
    "section_2": "Generate based on another field",
    "tags": [
        "content intelligence",
        "static strings"
    ],
    "title": "App Metadata",
    "updated_at": "2023-06-30T12:32:50.745Z",
    "updated_by": "bltcad6c6f379d5fb4d",
    "publish_details": {
        "environment": "blt6203b467e841f88e",
        "locale": "en-us",
        "time": "2023-06-30T12:32:55.597Z",
        "user": "bltcad6c6f379d5fb4d"
    }
}; exports.default = localAppMetadata;